import * as React from 'react';
import { createIllustration } from '@balance-web/illustration';

export const ReckonTimesheetsLogoName = 'ReckonTimesheetsLogo';
export const ReckonTimesheetsAppName = 'Timesheets';
export const ReckonTimesheetsAppLongName = 'Reckon Timesheets';
export const ReckonTimesheetsLogoDescription =
  'Reckon Timesheets Application Logo';

export const ReckonTimesheetsLogo = createIllustration(
  {
    name: ReckonTimesheetsLogoName,
    description: ReckonTimesheetsLogoDescription,
    width: 60,
    height: 60,
  },
  <React.Fragment>
    <mask
      id="b"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#a)"
      />
    </mask>
    <mask
      id="d"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#c)"
      />
    </mask>
    <g mask="url(#d)">
      <path fill="url(#e)" d="M0 0h60v60H0z" />
      <g clipPath="url(#f)" fillRule="evenodd" clipRule="evenodd" fill="#fff">
        <path d="M30 14.651c-9.037 0-16.364 7.03-16.364 15.7 0 8.67 7.327 15.7 16.364 15.7s16.364-7.03 16.364-15.7c0-8.67-7.327-15.7-16.364-15.7Zm-20 15.7c0-10.598 8.954-19.189 20-19.189s20 8.591 20 19.189S41.046 49.54 30 49.54 10 40.949 10 30.35Z" />
        <path d="M30 18.14c1.004 0 1.818.781 1.818 1.744v9.389l6.268 3.007c.898.43 1.262 1.478.813 2.34-.45.862-1.541 1.211-2.44.78l-7.272-3.489a1.738 1.738 0 0 1-1.005-1.56V19.884c0-.963.814-1.744 1.818-1.744Z" />
      </g>
      <g filter="url(#g)">
        <path d="M60 19 19 60h41V19Z" fill="#fff" fillOpacity={0.2} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.361 43.815v9.735H51.1a2.377 2.377 0 0 0 2.374-2.373v-7.362a2.377 2.377 0 0 0-2.374-2.373h-7.364a2.377 2.377 0 0 0-2.375 2.373Zm-1 10.735H51.1a3.377 3.377 0 0 0 3.374-3.373v-7.362a3.377 3.377 0 0 0-3.374-3.373h-7.364a3.377 3.377 0 0 0-3.375 3.373V54.55Z"
        fill="#fff"
      />
      <path
        d="M44.566 43.721h3.36c.951 0 1.688.281 2.17.75.402.415.63.963.63 1.62v.026c0 1.232-.737 1.981-1.782 2.276l2.036 2.85H49.4l-1.848-2.623h-1.66v2.624h-1.326V43.72Zm3.267 3.735c.95 0 1.553-.496 1.553-1.259v-.026c0-.803-.576-1.245-1.566-1.245h-1.928v2.53h1.941Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00DAAA" />
        <stop offset={1} stopColor="#00BFFF" />
      </linearGradient>
      <clipPath id="f">
        <path
          fill="#fff"
          transform="translate(10 11.162)"
          d="M0 0h40v38.377H0z"
        />
      </clipPath>
      <filter
        id="g"
        x={11}
        y={11}
        width={57}
        height={57}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_13772_48"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_13772_48"
          result="shape"
        />
      </filter>
    </defs>
  </React.Fragment>
);
